<template>
	<div>
	<ContentHeader title="Stok Produk" />
     <div class="content-header">
		  <div class="container-fluid">

      <div class="card">
        <div class="card-header">
          <h3 class="card-title"><router-link to="/stok-obat"><div style="#007bff">Stok Obat</div></router-link></h3>

          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <div class="card-body" style="display: block;">
          <table class="table table-bordered table-hover">
  				  <thead>
  				    <tr>
  				      <th scope="col" class="text-sm">KODE</th>
                <th scope="col" class="text-sm">NAMA</th>
                <th scope="col" class="text-sm">JUMLAH STOK</th>
                <th scope="col" class="text-sm">SATUAN</th>
                <th scope="col" class="text-sm" v-if="cabang.jenis == 'pusat'">HARGA JUAL</th>
                <th scope="col" class="text-sm" v-if="cabang.jenis == 'cabang'">HARGA JUAL PASIEN</th>
  				    </tr>
  				  </thead>
  				  <tbody>
  				    <tr v-for="row in stok_obat" :key="row.id">
  				      <td class="text-sm" scope="row">{{ row.produk.kode }}</td>
                <td class="text-sm" scope="row">{{ row.produk.nama }}</td>
                <td class="text-sm" scope="row">{{ row.stok_akhir }}</td>
                <td class="text-sm" scope="row">{{ row.produk.satuan }}</td>
                <td class="text-sm" scope="row" v-if="cabang.jenis == 'pusat'">{{ format_nominal(row.produk.harga_jual) }}</td>
                <td class="text-sm" scope="row" v-if="cabang.jenis == 'cabang'">{{ format_nominal(row.produk.harga_jual_pasien) }}</td>
  				    </tr>
  				  </tbody>
  				</table>
          <p class="text-center mt-2" v-if="cek_stok_obat">Data Masih Kosong</p>
          <p class="text-center mt-2" v-if="memuat_stok_obat">Memuat Data...</p>
        </div>
        <!-- /.card-body -->
        <div class="card-footer" style="display: block;">
          
        </div>
        <!-- /.card-footer-->
      </div>

      <div class="card">
        <div class="card-header">
          <h3 class="card-title"><router-link to="/stok-percetakan"><div style="#007bff">Stok Percetakan</div></router-link></h3>

          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <div class="card-body" style="display: block;">
          <table class="table table-bordered table-hover">
  				  <thead>
  				    <tr>
  				      <th scope="col" class="text-sm">KODE</th>
                <th scope="col" class="text-sm">NAMA</th>
                <th scope="col" class="text-sm">JUMLAH STOK</th>
                <th scope="col" class="text-sm">SATUAN</th>
                <th scope="col" class="text-sm" v-if="cabang.jenis == 'pusat'">HARGA JUAL</th>
                <th scope="col" class="text-sm" v-if="cabang.jenis == 'cabang'">HARGA JUAL PASIEN</th>
  				    </tr>
  				  </thead>
  				  <tbody>
  				    <tr v-for="row in percetakan" :key="row.id">
                <td class="text-sm" scope="row">{{ row.produk.kode }}</td>
                <td class="text-sm" scope="row">{{ row.produk.nama }}</td>
                <td class="text-sm" scope="row">{{ row.stok_akhir }}</td>
                <td class="text-sm" scope="row">{{ row.produk.satuan }}</td>
                <td class="text-sm" scope="row" v-if="cabang.jenis == 'pusat'">{{ format_nominal(row.produk.harga_jual) }}</td>
                <td class="text-sm" scope="row" v-if="cabang.jenis == 'cabang'">{{ format_nominal(row.produk.harga_jual_pasien) }}</td>
              </tr>
            </tbody>
          </table>
          <p class="text-center mt-2" v-if="cek_percetakan">Data Masih Kosong</p>
          <p class="text-center mt-2" v-if="memuat_percetakan">Memuat Data...</p>
        </div>
        <!-- /.card-body -->
        <div class="card-footer" style="display: block;">
          
        </div>
        <!-- /.card-footer-->
      </div>


      <div class="card">
        <div class="card-header">
          <h3 class="card-title"><router-link to="/stok-alat-kesehatan"><div style="#007bff">Stok Alat Kesehatan</div></router-link></h3>

          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <div class="card-body" style="display: block;">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th scope="col" class="text-sm">KODE</th>
                <th scope="col" class="text-sm">NAMA</th>
                <th scope="col" class="text-sm">JUMLAH STOK</th>
                <th scope="col" class="text-sm">SATUAN</th>
                <th scope="col" class="text-sm" v-if="cabang.jenis == 'pusat'">HARGA JUAL</th>
                <th scope="col" class="text-sm" v-if="cabang.jenis == 'cabang'">HARGA JUAL PASIEN</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in alat_kesehatan" :key="row.id">
                <td class="text-sm" scope="row">{{ row.produk.kode }}</td>
                <td class="text-sm" scope="row">{{ row.produk.nama }}</td>
                <td class="text-sm" scope="row">{{ row.stok_akhir }}</td>
                <td class="text-sm" scope="row">{{ row.produk.satuan }}</td>
                <td class="text-sm" scope="row" v-if="cabang.jenis == 'pusat'">{{ format_nominal(row.produk.harga_jual) }}</td>
                <td class="text-sm" scope="row" v-if="cabang.jenis == 'cabang'">{{ format_nominal(row.produk.harga_jual_pasien) }}</td>
              </tr>
            </tbody>
          </table>
          <p class="text-center mt-2" v-if="cek_alat_kesehatan">Data Masih Kosong</p>
          <p class="text-center mt-2" v-if="memuat_alat_kesehatan">Memuat Data...</p>
        </div>
        <!-- /.card-body -->
        <div class="card-footer" style="display: block;">
          
        </div>
        <!-- /.card-footer-->
      </div>
		</div>
	 </div>
  </div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader'

import axios from 'axios'
import store from '@/store'
import { ref, onMounted, computed } from 'vue' 
import format_nominal from '@/format_nominal.js'

const user   = computed(() => store.getters['auth/user'])
const cabang = computed(() => store.getters['auth/cabang'])

const { stok_obat, getStokObat, cek_stok_obat, memuat_stok_obat } = useStokObat(user, cabang.value)

const { percetakan, getStokPercetakan, cek_percetakan, memuat_percetakan } = useStokPercetakan(user, cabang.value)

const { alat_kesehatan, getStokAlkes, cek_alat_kesehatan, memuat_alat_kesehatan } = useStokAlkes(user, cabang.value)

onMounted(() => {
  getStokObat()
  getStokPercetakan()
  getStokAlkes()
})

function useStokObat (user, cabang) {
  const stok_obat = ref([])
  const cek_stok_obat = ref(false)
  const memuat_stok_obat = ref(true)

  const getStokObat = async () => {
    let { data } = await axios.get(`api/stok_produk/getStokProdukWhereJenisPaginate/${user.value.cabang_id}/obat/${5}/emptyParameter/semua/emptyParameter/${cabang.tipe}`)

    if (data == 'kosong') {
      memuat_stok_obat.value = false
      cek_stok_obat.value = true
      console.log(data)
    } else {
      memuat_stok_obat.value = false
      cek_stok_obat.value = false
      stok_obat.value = data.data
    }
  }

  return { stok_obat, getStokObat, cek_stok_obat, memuat_stok_obat }
}

function useStokPercetakan (user, cabang) {
  const percetakan = ref([])
  const cek_percetakan = ref(false)
  const memuat_percetakan = ref(true)

  const getStokPercetakan = async () => {
    let { data } = await axios.get(`api/stok_produk/getStokProdukWhereJenisPaginate/${user.value.cabang_id}/percetakan/${5}/emptyParameter/semua/emptyParameter/${cabang.tipe}`)

    if (data == 'kosong') {
      memuat_percetakan.value = false
      cek_percetakan.value = true
      console.log(data)
    } else {
      memuat_percetakan.value = false
      cek_percetakan.value = false
      percetakan.value = data.data
    }
  }

  return { percetakan, getStokPercetakan, cek_percetakan, memuat_percetakan }
}

function useStokAlkes (user, cabang) {
  const alat_kesehatan = ref([])
  const cek_alat_kesehatan = ref(false)
  const memuat_alat_kesehatan = ref(true)

  const getStokAlkes = async () => {
    let { data } = await axios.get(`api/stok_produk/getStokProdukWhereJenisPaginate/${user.value.cabang_id}/alat_kesehatan/${5}/emptyParameter/semua/emptyParameter/${cabang.tipe}`)

    if (data == 'kosong') {
      memuat_alat_kesehatan.value = false
      cek_alat_kesehatan.value = true
      console.log(data)
    } else {
      memuat_alat_kesehatan.value = false
      cek_alat_kesehatan.value = false
      alat_kesehatan.value = data.data
    }
  }

  return { alat_kesehatan, getStokAlkes, cek_alat_kesehatan, memuat_alat_kesehatan }
}
</script>

<style>
	
</style>